// View More Link
.view-more {
  display: block;
  padding: 1.1rem 0;
  text-align: center;
  margin-top: 0.5rem;
  font-weight: $font-weight-bold;
  font-size: 0.9rem;
  @include transition(color 300ms);

  &:not(.view-more--light) {
    color: $text-muted;

    &:hover {
      color: darken($text-muted, 10%);
    }
  }
}

.view-more--light {
  color: $white;

  &:hover {
    color: rgba($white, 0.8);
  }
}

.card-body {
  .view-more {
    padding: 1rem 0 0;
  }
}

// Action Icons
.actions {
  [dropdown] {
    display: inline-block;
  }

  &:not(.actions--inverse) {
    .actions__item {
      color: lighten($body-color, 30%);

      &:hover {
        color: $body-color;
      }
    }
  }
}

.actions__item {
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  @include transition(color 300ms);

  & > i {
    display: inline-block;
    width: 100%;
  }
}

.actions--inverse {
  .actions__item {
    color: rgba($white, 0.7);
    
    &:hover {
      color: $white;
    }
  }
}


// Icon Lists
.icon-list {
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    position: relative;
    padding: 0.25rem 0;

    address {
      display: inline-block;
      vertical-align: top;
    }

    & > i {
      width: 2.5rem;
      text-align: center;
      font-size: 1.25rem;
      top: 0.125rem;
      position: relative;
      margin-left: -0.5rem;
    }
  }
}


// Avatars
.avatar-img,
.avatar-char {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 1.5rem;
}

.avatar-char {
  line-height: 3rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;
  color: $white;
  font-style: normal;

  & > .zmdi {
    line-height: 3.1rem;
  }
}


// Backdrops
.ma-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &[data-ma-action="navigation-close"] {
    z-index: $sidebar-z-index - 1;
  }
}


// Dashboard
.widget-lists {
  column-gap: $grid-gutter-width;

  @media(min-width: 1500px) {
    column-count: 3;
  }

  @media (min-width: 768px) and (max-width: 1499px) {
    column-count: 2;
  }

  @media screen and (max-width: 767px) {
    column-count: 1;
  }
}