/* Importing Bootstrap SCSS file. */

// Functions
@import "../node_modules/bootstrap/scss/functions";

// Site + Bootstrap Variable and Mixins
@import "./assets/scss/variables";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/scss/mixins";
@import "./assets/scss/bootstrap-overrides/mixins";
@import "./assets/scss/bootstrap-imports";
@import "../node_modules/bootstrap-select/dist/css/bootstrap-select.css";

// Site Styles
@import "./assets/scss/fonts";
@import "./assets/scss/generics";
@import "./assets/scss/base";
@import "./assets/scss/layout";
@import "./assets/scss/charts";
@import "./assets/scss/toggle-switch";
@import "./assets/scss/listview";
@import "./assets/scss/page-loader";
@import "./assets/scss/theme";

// Bootstrap Overrides
@import "./assets/scss/bootstrap-overrides/dropdown";
@import "./assets/scss/bootstrap-overrides/media";
@import "./assets/scss/bootstrap-overrides/progress";
@import "./assets/scss/bootstrap-overrides/card";
@import "./assets/scss/bootstrap-overrides/buttons";
@import "./assets/scss/bootstrap-overrides/tables";
@import "./assets/scss/bootstrap-overrides/forms";
@import "./assets/scss/bootstrap-overrides/input-groups";
@import "./assets/scss/bootstrap-overrides/custom-forms";
@import "./assets/scss/bootstrap-overrides/pagination";
@import "./assets/scss/bootstrap-overrides/datepicker";
@import "./assets/scss/bootstrap-overrides/timepicker";
@import "./assets/scss/bootstrap-overrides/alerts";
@import "./assets/scss/bootstrap-overrides/close";
@import "./assets/scss/bootstrap-overrides/breadcrumbs";
@import "./assets/scss/bootstrap-overrides/accordion";
@import "./assets/scss/bootstrap-overrides/carousel";
@import "./assets/scss/bootstrap-overrides/modal";
@import "./assets/scss/bootstrap-overrides/popovers";
@import "./assets/scss/bootstrap-overrides/ratings";
@import "./assets/scss/bootstrap-overrides/sortable";
@import "./assets/scss/bootstrap-overrides/tabs";
@import "./assets/scss/bootstrap-overrides/tooltip";

// Vendor Overrides
@import "./assets/scss/vendor-overrides/sparklines";
@import "./assets/scss/vendor-overrides/select2";
@import "./assets/scss/vendor-overrides/angular2-color-picker";
@import "./assets/scss/vendor-overrides/nouislider";
@import "./assets/scss/vendor-overrides/easypiecharts";
@import "./assets/scss/vendor-overrides/perfect-scrollbar";

// App Global Styles
@import "./assets/scss/misc";

//ngx-datatable
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

//calendar
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.cal-month-view .cal-day-badge {
  display: none;
}

.required-field:after {
  content: " *";
  color: red;
}

#loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
