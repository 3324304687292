[dropdown] {
  position: relative;
}

.dropdown-item {
  cursor: pointer;
  @include transition(background-color 300ms, color 300ms);
}

.dropdown-menu {
  @include animate(fadeIn, 300ms);
  box-shadow: $dropdown-box-shadow;
}

.dropdown-menu--block {
  width: 320px;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.dropdown-menu--icon {
  .dropdown-item {
    & > i {
      line-height: 100%;
      vertical-align: top;
      font-size: 1.4rem;
      width: 2rem;
    }
  }
}