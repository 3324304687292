// Pie Charts
.chart-pie {
  display: inline-block;
  position: relative;
}

.chart-pie__value {
  position: absolute;
  color: $white;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;

  &:after {
    content: "%";
    font-size: 12px;
  }
}

.chart-pie__title {
  margin-top: -2px;
  line-height: 15px;
  font-size: 11px;
}
