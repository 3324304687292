// Options
$enable-transitions: true;

// Colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$red: #ff5652;
$pink: #ff85af;
$purple: #d559ea;
$deep-purple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$light-blue: #03a9f4;
$cyan: #00bcd4;
$teal: #39bbb0;
$green: #32c787;
$light-green: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deep-orange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blue-grey: #607d8b;
$black: #000000;
$white: #ffffff;

// Theme Colors
$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $amber,
  danger: $red,
  dark: $gray-700,
);

// Buttons
$btn-box-shadow: 0 2px 2px 0 rgba($black, 0.12);
$btn-focus-box-shadow: 0 2px 2px 0 rgba($black, 0.2);
$btn-active-box-shadow: $btn-focus-box-shadow;
$input-btn-padding-y: 0.6rem;
$input-btn-padding-x: 1rem;
$input-btn-line-height: 1.154;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.55rem;
$input-btn-line-height-sm: 1.5;
$input-btn-line-height-lg: 1.5;

// Typography
$font-family-sans-serif: "Roboto", sans-serif;
$text-muted: #9c9c9c;
$headings-color: #333;
$font-weight-bold: 500;

$font-family-icon: "Material-Design-Iconic-Font";
$font-size-root: 13px;

// Links
$link-color: $light-blue;
$link-hover-decoration: none;

// Body
$body-bg: #f3f3f3;
$body-color: #707070;

// Form
$input-bg: $white;
$input-disabled-bg: transparent;
$input-box-shadow: rgba(0, 0, 0, 255);
$input-border-color: lighten($gray-200, 1%);
$input-focus-border-color: $input-border-color;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$form-group-margin-bottom: 2rem;
$input-focus-box-shadow: none;

// Custom Forms
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-focus-box-shadow: none;
$custom-control-indicator-size: 1.308rem;
$custom-control-indicator-bg: transparent;
$custom-control-gutter: 2rem;
$custom-checkbox-indicator-icon-checked: none;
$custom-radio-indicator-icon-checked: none;
$custom-control-indicator-checked-bg: transparent;
$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: transparent;
$custom-control-indicator-disabled-bg: transparent;
$custom-control-indicator-checked-color: $white;

// Layout
$content-title-heading-color: #676767;

// Header
$header-height: 72px;
$header-shadow: 0 1px 10px rgba($black, 0.2);
$header-z-index: 10;

// Dropdown
$dropdown-border-width: 0;
$dropdown-border-color: transparent;
$dropdown-box-shadow: 0 4px 18px rgba($black, 0.11);
$dropdown-link-color: #6d6d6d;
$dropdown-link-active-color: $dropdown-link-color;
$dropdown-link-active-bg: $gray-200;
$dropdown-padding-y: 0.8rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-item-padding-y: 0.5rem;
$dropdown-spacer: 0;

// Border Radius
$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

// List Group
$list-group-bg: transparent;
$list-group-border-width: 0;
$list-group-hover-bg: black;
$list-group-active-bg: $light-blue;
$list-group-item-padding-x: 2rem;
$list-group-item-padding-y: 1rem;

// Progress Bar
$progress-box-shadow: none;
$progress-bar-color: $blue;
$progress-bg: $gray-200;
$progress-height: 3px;

// Card
$card-inner-border-radius: $border-radius;
$card-border-color: transparent;
$card-border-radius: $border-radius;
$card-spacer-x: 2.2rem;
$card-spacer-y: 2.1rem;
$card-cap-bg: transparent;
$card-img-overlay-padding: 0;
$card-shadow: 0 1px 2px rgba($black, 0.075);
$card-columns-margin: 2.3rem;

// Sidebars
$sidebar-width: 270px;
$sidebar-z-index: $header-z-index - 1;
$navigation-link-color: $body-color;
$navigation-link-active-color: $white;
$navigation-link-active-bg: rgba($black, 0.03);
$navigation-link-hover-bg: rgba($black, 0.03);

// Tabels
$table-border-color: lighten($gray-200, 3%);
$table-inverse-bg: $gray-700;
$table-inverse-border-color: lighten($gray-700, 5%);
$table-accent-bg: $table-border-color;
$table-hover-bg: $table-accent-bg;
$table-cell-padding: 1rem 1.5rem;
$table-cell-padding-sm: 0.75rem 1rem;
$table-head-bg: lighten($gray-200, 3%);

// Pagination
$pagination-border-width: 0;
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-bg: $gray-200;
$pagination-hover-bg: darken($pagination-bg, 5%);
$pagination-color: lighten($body-color, 10%);
$pagination-hover-color: darken($pagination-color, 5%);
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-color: $pagination-color;
$pagination-active-bg: lighten($light-blue, 10%);

// Input Group
$input-group-addon-border-color: $input-border-color;
$input-group-addon-bg: transparent;

// Code
$pre-color: $white;
$pre-bg: $gray-800;
$pre-border-color: $pre-bg;

// Alert
$alert-padding-y: 18px;
$alert-padding-x: 2rem;
$alert-link-font-weight: normal;
$alert-border-width: 0;

// Close
$close-font-weight: normal;
$close-text-shadow: none;

// Badges
$badge-padding-y: 0.4rem;
$badge-padding-x: 0.55rem;
$badge-font-size: 85%;
$badge-font-weight: 500;

// Breadcrumbs
$breadcrumb-divider: "\f30f";
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0.25rem;

// Carosuel
$carousel-control-prev-icon-bg: none;
$carousel-control-next-icon-bg: none;
$carousel-control-icon-width: 40px;
$carousel-control-opacity: 0.8;
$carousel-caption-color: rgba($white, 0.9);

// Modal
$modal-backdrop-opacity: 0.2;
$modal-content-box-shadow-xs: 0 5px 20px rgba($black, 0.07);
$modal-content-box-shadow-sm-up: $modal-content-box-shadow-xs;
$modal-content-border-width: 0;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-header-padding: 25px 30px 0;
$modal-inner-padding: 25px 30px;

// Popovers
$popover-border-width: 0;
$popover-box-shadow: 0 2px 30px rgba($black, 0.1);
$popover-arrow-outer-color: transparent;
$popover-inner-padding: 15px 10px;
$popover-header-bg: $white;

// Sortable
$sortable-border-color: $gray-100;

// Tabs
$nav-tabs-border-width: 2px;
$nav-tabs-border-color: $gray-200;
$nav-tabs-border-radius: 0;
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1.2rem;
$nav-link-padding: 1rem 1.2rem;
$nav-tabs-link-active-bg: $white;

// Tooltips
$tooltip-padding-y: 0.7rem;
$tooltip-padding-x: 1.1rem;
$tooltip-bg: $gray-700;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;

// Backdrop
$backdrop-z-index: 100;

// Listview
$listview-item-active-bg: $gray-100;
$listview-item-hover-bg: $gray-100;
$listview-item-striped-bg: $gray-100;
$listview-invert-item-active-bg: rgba($white, 0.025);
$listview-invert-item-hover-bg: rgba($white, 0.025);
$listview-invert-item-striped-bg: rgba($white, 0.1);
$listview-border-color: $gray-100;

// HR
$hr-border-color: darken($gray-100, 2%);
